/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button, FormGroup, InputGroup, InputGroupAddon, InputGroupText, FormText, Input, Card } from "reactstrap";
import classnames from "classnames";
import NumberFormat from "react-number-format";

class RfidErklaerung extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPrice: 0,
      unitPrice: 0.89,
    };
    this.calculatePricing = this.calculatePricing.bind(this);
  }

  calculatePricing(event) {
    const quantity = event.target.value;

    if (!quantity || quantity < 500) {
      this.setState({
        totalPrice: 0,
        unitPrice: 0,
      });
    }

    let unitPrice = 0;
    switch (true) {
      case quantity < 1000:
      default:
        unitPrice = 0.89;
        break;
      case quantity < 5000:
        unitPrice = 0.69;
        break;
      case quantity < 10000:
        unitPrice = 0.59;
        break;
      case quantity >= 10000:
        unitPrice = 0.49;
        break;
    }

    const totalPrice = quantity * unitPrice;

    this.setState({
      totalPrice: totalPrice,
      unitPrice: unitPrice,
    });
  }

  render() {
    return (
      <>
        <Container className="mb-5">
          <Card className="bg-gradient-primary shadow-lg border-0 text-white">
            <div className="p-5">
              <Row className="align-items-center">
                <Col lg="8">
                  <h3 className="text-white">
                    Wie viele Hüllen benötigen Sie?
                  </h3>
                  <FormGroup
                    className={classnames({
                      focused: this.state.quantityFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-ungroup" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Stückzahl"
                        type="number"
                        min="500"
                        step="1"
                        onFocus={(e) =>
                          this.setState({ quantityFocused: true })
                        }
                        onBlur={(e) =>
                          this.setState({ quantityFocused: false })
                        }
                        onChange={this.calculatePricing}
                      />
                    </InputGroup>
                    <FormText color="white">Mindeststückzahl: 500</FormText>
                  </FormGroup>
                  <table className="table table-sm">
                    <caption className="py-0 text-right">
                      <FormText color="white">zzgl. Ust.</FormText>
                    </caption>
                    <tbody>
                      <tr>
                        <td>Stückpreis</td>
                        <td className="text-right">
                          <NumberFormat
                            value={this.state.unitPrice}
                            displayType={"text"}
                            suffix={" €"}
                            decimalSeparator={","}
                            thousandSeparator={"."}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Gesamtpreis</b>
                        </td>
                        <td className="text-right">
                          <b>
                            <NumberFormat
                              value={this.state.totalPrice}
                              displayType={"text"}

                              suffix={" €"}
                              decimalSeparator={","}
                              thousandSeparator={"."}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col className="ml-lg-auto" lg="3">
                  <Button
                    block
                    className="btn-white"
                    color="default"
                    href="#kontakt"
                    size="lg"
                  >
                    Jetzt Anfragen
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </Container>
        <Container id="rfid-preisliste">
          <Row className="justify-content-left align-items-center">
            <Col className="mb-2 mb-lg-0" lg="10">
              <h3 className="font-weight-light mb-3">Preisliste</h3>
              <div className="table-responsive">

              </div>
              <div className="table-responsive">
                <table className="table">
                  <caption>Alle Preise sind in Euro und zzgl. USt.</caption>
                  <thead>
                    <tr>
                      <th scope="col">Stückzahl</th>
                      <th scope="col">Stückpreis</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">500</th>
                      <td>0,89 €</td>
                    </tr>
                    <tr>
                      <th scope="row">1.000</th>
                      <td>0,69 €</td>
                    </tr>
                    <tr>
                      <th scope="row">5.000</th>
                      <td>0,59 €</td>
                    </tr>
                    <tr>
                      <th scope="row">10.000</th>
                      <td>0,49 €</td>
                    </tr>
                    <tr>
                      <th scope="row">ab 20.000</th>
                      <td>individuelle Anfrage</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button
                className="btn-icon mb-3 mb-sm-0"
                color="primary"
                href="#kontakt"
              >
                <span className="btn-inner--text">Jetzt Anfragen</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default RfidErklaerung;
