/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import axios from 'axios';

import Nutzerbewertung from "./Nutzerbewertung";

class Bewertungen extends React.Component {
  state = {
    userReviews: [],
  }

  componentDidMount() {
    const requestUserReviews = axios.get(process.env.REACT_APP_API_BASE + "/user-reviews/getAllVisibleUserReviews.php");

    axios.all([requestUserReviews]).then(axios.spread((...responses) => {
      const responseUserReviews = responses[0];
      this.setState({ userReviews: responseUserReviews.data.records });
    })).catch(errors => {
      console.error(errors);
    })

  }

  render() {
    if (this.state.userReviews != null && this.state.userReviews.length > 0) {
      return (
        <>
          <section className="section bg-gradient-primary">
            <Container className="py-md">
              <Row className="align-items-center justify-content-center text-white">
                <Col lg="6">
                  <h1 className="text-white font-weight-light">
                    Das sagen<br />unsere Kunden
                </h1>
                </Col>
                {
                  this.state.userReviews.map((userReview, i) => {
                    // set last element full size if its alone on its row (odd array numbers)
                    let size = 6;
                    if (i === (this.state.userReviews.length) - 1 && i % 2 === 1) {
                      size = 12;
                    }
                    if (userReview.first_name === null) {
                      userReview.first_name = "";
                    }
                    if (userReview.last_name === null) {
                      userReview.last_name = "";
                    }
                    if (userReview.text === null) {
                      userReview.text = "";
                    }
                    // Return the element. Also pass key     
                    return (
                      <Col lg={size} key={"userReview-" + userReview.id}>
                        <Nutzerbewertung
                          name={userReview.first_name + " " + userReview.last_name}
                          text={userReview.text}
                          stars={userReview.stars}
                          productname={userReview.product_title}
                          productlink={"/produkte?id=" + userReview.product_id} />
                      </Col>
                    )
                  })
                }
              </Row>
            </Container>
          </section>
        </>
      );
    } else {
      return null;
    }
  }
}

export default Bewertungen;
